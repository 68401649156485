import config from 'config';
import { DEFAULT_BRANCH, GENERAL_BRANCH, LV_BRANCH, PREGNANCY_BRANCH } from './branching';

export interface ICookiePixel {
  name: string;
  id: string;
  config?: { [field: string]: string };
}

interface ICookieAnalyticList {
  [cookieName: string]: ICookiePixel[];
}

export const STRICTLY_NECESSARY_COOKIE = 'C0001';
export const PERFORMANCE_COOKIE = 'C0002';
export const FUNCTIONAL_COOKIE = 'C0003';
export const TARGETING_COOKIE = 'C0004';
export const SOCIAL_MEDIA_COOKIE = 'C0005';

export const COOKIE_GROUP_LIST = [STRICTLY_NECESSARY_COOKIE, PERFORMANCE_COOKIE, TARGETING_COOKIE];

export const PIXEL_ID_DEFAULT_BRANCHES = [LV_BRANCH, DEFAULT_BRANCH, GENERAL_BRANCH];

export const getPixelIdByBranchName = (branchName: string | null): string => {
  if (branchName && PIXEL_ID_DEFAULT_BRANCHES.includes(branchName)) {
    return config.PIXEL_ID_DEFAULT;
  }
  if (branchName === PREGNANCY_BRANCH) {
    return config.PIXEL_WEB_FH_4;
  }

  return config.PIXEL_ID;
};

export const getCookieAnalyticList = (branchName: string | null): ICookieAnalyticList => {
  const pixelId = getPixelIdByBranchName(branchName);

  return {
    [STRICTLY_NECESSARY_COOKIE]: [],
    [PERFORMANCE_COOKIE]: [],
    [FUNCTIONAL_COOKIE]: [],
    [TARGETING_COOKIE]: [
      // {
      //     name: 'ga',
      //     id: config.GOOGLE_ID,
      // },
      {
        name: 'pixel',
        id: pixelId,
      },
      {
        name: 'pinterest',
        id: config.PINTEREST_ID,
      },
    ],
    [SOCIAL_MEDIA_COOKIE]: [],
  };
};
