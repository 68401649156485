export const MOTHERHOOD_BRANCH = 'motherhood';
export const LV_BRANCH = 'lv';
export const PREGNANCY_BRANCH = 'pregnancy';

export const GENERAL_BRANCH = 'general';
export const DEFAULT_BRANCH = 'default';

export const EMAIL_BRANCH = 'email';

export const MAIN_BRANCHES = [MOTHERHOOD_BRANCH, LV_BRANCH, PREGNANCY_BRANCH];
export const DEFAULT_BRANCHES = [GENERAL_BRANCH, DEFAULT_BRANCH];
