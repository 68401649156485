import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectOnboardingConfig } from 'redux/Testania/selectors';

/* On inter screens bg should be #fff */
const interScreens = [
  '/ob-choose-us-cleanse',
  '/ob-choose-us-real',
  '/ob-choose-us-cleanse-start',
  '/ob-goal-gp-new',
  '/ob-social-proof-flow',
  '/ob-gn-chart',
  '/ob-doctor-comment',
  '/ob-gn-loading',
  '/ob-gn-gpfaster-1',
  // '/ob-gn-articles',
  // '/ob-gn-hbanswer',
  '/ob-gn-gpfaster-2',
  // '/ob-gn-we-are-glad',
  '/ob-enter-email',
  '/ob-enter-email-sex-new',
  '/ob-before-after',
  '/ob-save-money-chart',
  '/ob-welcome',
  //
  '/ob-gp-explanation',
  '/ob-best-graph',
  '/ob-examining',
  '/ob-body-signals',
  '/ob-pregnancy-support',
  '/ob-fertility-target',
  '/ob-health-check',
  '/ob-confiding',
  '/ob-boost-chart',
  '/ob-sex-expert',
  '/ob-meno-graph',
  '/ob-pg-choose-us',
  '/ob-pg-experts',
  '/ob-pg-baby-exploration',
  '/ob-pg-baby-mind',
  '/ob-pg-question-answered',
  '/ob-pg-plan-creation',
  '/ob-pg-before-after',
  '/ob-pg-goal',
];

const blueScreens = [
  '/ob-block-eggs',
  '/ob-block-intimacy',
  '/ob-block-hormones',
  '/ob-gn-articles',
  '/ob-gn-hbanswer',
  '/ob-block-psych',
  '/ob-gn-we-are-glad',
  // '/ob-welcome',
  '/ob-confiding',
  '/ob-sex-expert',
  '/ob-pg-admire',
  '/ob-pg-th-honest',
];

const useSetBackground = () => {
  const onboardingConfig = useSelector(selectOnboardingConfig) || [];
  const { pathname } = useLocation();

  const pageId = pathname.replace(/(\/([a-z]{2})\/)|\//g, '');
  const currentLocationId = pageId.replace(/-/g, '_');

  const isObScreen = onboardingConfig.find(({ id }) => id === currentLocationId);
  const isBlueScreen = blueScreens.includes(pathname);

  useEffect(() => {
    if (!onboardingConfig) return;

    if (onboardingConfig && isObScreen && !interScreens.includes(pathname)) {
      document.documentElement.style.backgroundColor = '#F8F8F8';
      // @ts-ignore
      document.querySelector('meta[name="theme-color"]').setAttribute('content', isBlueScreen ? '#4C61C7' : '#F8F8F8');

    } else {
      document.documentElement.style.backgroundColor = 'unset';
      // @ts-ignore
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#FFFFFF');
    }
  }, [pathname, onboardingConfig]);
};

export default useSetBackground;
