import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';

import { getCurrentLocalization, getLanguageWithCountry, getLocalizationFromAvailable } from 'helpers/localization';

import { DEFAULT_LANGUAGE, DEFAULT_PRELOAD_LANGUAGE } from 'constants/localization';

import { getConfigBy } from 'pages/GeneralPages/InitTestania/config/helpers';
import { EMAIL_BRANCH, LV_BRANCH, PREGNANCY_BRANCH } from '../constants/branching';

const localeFromStorage = localStorage.getItem('language');

const navigatorLanguage = getLocalizationFromAvailable();
const languageFromURL = getCurrentLocalization();

const language = navigatorLanguage || languageFromURL || localeFromStorage || DEFAULT_LANGUAGE;
const languageWithCountry = getLanguageWithCountry(language) || DEFAULT_PRELOAD_LANGUAGE;
const branchName = getConfigBy('branch-name');

const isLvBranchName = branchName === LV_BRANCH;
const isPregnancyBranchName = branchName === PREGNANCY_BRANCH;
const isEmailBranchName = branchName === EMAIL_BRANCH;

const isDefaultPreloadLanguage = isLvBranchName || isPregnancyBranchName || isEmailBranchName;

localStorage.setItem('language', language);

const lng = isDefaultPreloadLanguage ? DEFAULT_PRELOAD_LANGUAGE : languageWithCountry;

i18n.use(backend)
  .use(initReactI18next)
  .init({
    lng: lng,
    fallbackLng: [DEFAULT_PRELOAD_LANGUAGE],
    ns: ['general', 'sex_funnel'],
    fallbackNS: 'sex_funnel',
    backend: {
      loadPath: `/locales/{{ns}}/${lng}/translation.json?cb=${(new Date()).getTime()}`,
    },
    preload: [DEFAULT_PRELOAD_LANGUAGE],
    debug: false,
    react: {
      useSuspense: false,
    },
    nsSeparator: false,
    keySeparator: false,
  });

export default i18n;
