import * as actionTypes from './actionTypes';

import { OnboardingScreensList, SkipOnboarding } from 'types/onboarding/onboardingStore';

export function setUrlParams(url: { [key: string]: string }) {
  return {
    type: actionTypes.SET_URL_PARAMS,
    payload: url,
  } as const;
}

export function setOnboardingScreensList(screensList: OnboardingScreensList) {
  return {
    type: actionTypes.SET_ONBOARDING_SCREENS_LIST,
    payload: screensList,
  } as const;
}

export function setCycleRegularity(cycleRegularity: string) {
  return {
    type: actionTypes.SET_CYCLE_REGULARITY,
    payload: cycleRegularity,
  } as const;
}

export function setCycleDuration(cycleDuration: number) {
  return {
    type: actionTypes.SET_CYCLE_DURATION,
    payload: cycleDuration,
  } as const;
}

export function setPeriodDuration(periodDuration: number) {
  return {
    type: actionTypes.SET_PERIOD_DURATION,
    payload: periodDuration,
  } as const;
}

export function setUserGoal(userGoal: string) {
  return {
    type: actionTypes.SET_USER_GOAL,
    payload: userGoal,
  } as const;
}

export function setBirthYear(birthYear: number) {
  return {
    type: actionTypes.SET_BIRTH_YEAR,
    payload: birthYear,
  } as const;
}

export function setHealthProblems(healthProblems: string[]) {
  return {
    type: actionTypes.SET_HEALTH_PROBLEMS,
    payload: healthProblems,
  } as const;
}

export function setFirstPregnancy(firstPregnancy: string) {
  return {
    type: actionTypes.SET_FIRST_PREGNANCY,
    payload: firstPregnancy,
  } as const;
}

export function setLifestyleAdjustment(lifestyleAdjustment: string) {
  return {
    type: actionTypes.SET_LIFESTYLE_ADJUSTMENT,
    payload: lifestyleAdjustment,
  } as const;
}

export function setJobType(jobType: string) {
  return {
    type: actionTypes.SET_JOB_TYPE,
    payload: jobType,
  } as const;
}

export function setConceptionLength(conceptionLength: string) {
  return {
    type: actionTypes.SET_CONCEPTION_LENGTH,
    payload: conceptionLength,
  } as const;
}

export function setOvulationCalculation(ovulationCalculation: string) {
  return {
    type: actionTypes.SET_OVULATION_CALCULATION,
    payload: ovulationCalculation,
  } as const;
}

export function setDoctorCheckup(doctorCheckup: string) {
  return {
    type: actionTypes.SET_DOCTOR_CHECKUP,
    payload: doctorCheckup,
  } as const;
}

export function setReproductiveDisorder(reproductiveDisorder: string[]) {
  return {
    type: actionTypes.SET_REPRODUCTIVE_DISORDER,
    payload: reproductiveDisorder,
  } as const;
}

export function setPrenatalSupplements(prenatalSupplements: string) {
  return {
    type: actionTypes.SET_PRENATAL_SUPPLEMENTS,
    payload: prenatalSupplements,
  } as const;
}

export function setInterestingTopicsGp(interestingTopicsGp: string[]) {
  return {
    type: actionTypes.SET_INTERESTING_TOPICS_GP,
    payload: interestingTopicsGp,
  } as const;
}

export function setMoodSwings(moodSwings: string) {
  return {
    type: actionTypes.SET_MOOD_SWINGS,
    payload: moodSwings,
  } as const;
}

export function setMentalHealth(mentalHealth: string[]) {
  return {
    type: actionTypes.SET_MENTAL_HEALTH,
    payload: mentalHealth,
  } as const;
}

export function setSexIssues(sexIssues: string[]) {
  return {
    type: actionTypes.SET_SEX_ISSUES,
    payload: sexIssues,
  } as const;
}

export function setBirthControl(birthControl: string[]) {
  return {
    type: actionTypes.SET_BIRTH_CONTROL,
    payload: birthControl,
  } as const;
}

export function setInterestingTopics(interestingTopics: string[]) {
  return {
    type: actionTypes.SET_INTERESTING_TOPICS_TC,
    payload: interestingTopics,
  } as const;
}

export function setExperiencedSymptoms(experiencedSymptoms: string[]) {
  return {
    type: actionTypes.SET_EXPERIENCED_SYMPTOMS,
    payload: experiencedSymptoms,
  } as const;
}

export function setHeight(height: number) {
  return {
    type: actionTypes.SET_HEIGHT,
    payload: height,
  } as const;
}

export function setWeight(weight: number) {
  return {
    type: actionTypes.SET_WEIGHT,
    payload: weight,
  } as const;
}

export function setHormonalContraception(value: string) {
  return {
    type: actionTypes.SET_HORMONAL_CONTRACEPTION,
    payload: value,
  } as const;
}

export function setLastPeriod(value: string) {
  return {
    type: actionTypes.SET_LAST_PERIOD,
    payload: value,
  } as const;
}

export function setSymptoms(symptoms: string[]) {
  return {
    type: actionTypes.SET_SYMPTOMS,
    payload: symptoms,
  } as const;
}

export function skipOnboarding(payload: SkipOnboarding) {
  return {
    type: actionTypes.SKIP_ONBOARDING,
    payload: payload,
  } as const;
}

export function initAutoLogin() {
  return {
    type: actionTypes.INIT_AUTO_LOGIN,
  } as const;
}

export function setHormonalBalance(value: string) {
  return {
    type: actionTypes.SET_HORMONAL_BALANCE,
    payload: value,
  } as const;
}

export function setWhenToConceive(value: string) {
  return {
    type: actionTypes.SET_WHEN_TO_CONCEIVE,
    payload: value,
  } as const;
}

export function setGoalSexNew(values: string[]) {
  return {
    type: actionTypes.SET_GOAL_SEX_NEW,
    payload: values,
  } as const;
}

export function setRelationships(value: string) {
  return {
    type: actionTypes.SET_RELATIONSHIPS,
    payload: value,
  } as const;
}

export function setSexPregnant(value: string) {
  return {
    type: actionTypes.SET_SEX_PREGNANT,
    payload: value,
  } as const;
}

export function setSexMenstruation(value: string) {
  return {
    type: actionTypes.SET_SEX_MENSTRUATION,
    payload: value,
  } as const;
}

export function setGoalGpNew(values: string[]) {
  return {
    type: actionTypes.SET_GOAL_GP_NEW,
    payload: values,
  } as const;
}

export function setGnHealthIssues(values: string[]) {
  return {
    type: actionTypes.SET_GN_HEALTH_ISSUES,
    payload: values,
  } as const;
}

export function setGoalPregnancy(values: string[]) {
  return {
    type: actionTypes.SET_GOAL_PREGNANCY,
    payload: values,
  } as const;
}
