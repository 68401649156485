import { AnalyticUtils, uaParser } from 'wikr-core-analytics';

import config from 'config';

import { DEFAULT_LANGUAGE } from 'constants/localization';
import {
  DAYS_PER_EIGHT_MONTH,
  DAYS_PER_FIVE_MONTH,
  DAYS_PER_FOUR_MONTH,
  DAYS_PER_MONTH,
  DAYS_PER_SEVEN_MONTH,
  DAYS_PER_SIX_MONTH,
  DAYS_PER_THREE_MONTH,
  DAYS_PER_TWO_MONTH,
  DAYS_PER_WEEK,
  DAYS_PER_YEAR,
} from 'constants/payments';

import { COUNTRIES_LIST } from 'constants/countriesList';
import { getUrlParams as getUrlParamsFromUrl } from './utils';

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

const KEYS_TO_REMOVE = [
  'currentBranchName',
  'token',
  'loginStatus',
  'paidStatus',
  'config',
  'completedOBs',
  'paymentType',
  'page-is-already-reload',
  'persist:root',
  'release_date',
  'paymentFailed',
  // 'uuid',
  'testania_name',
  'pusherTransportTLS',
  'testaniaResponseStatus',
  'branch-name',
  'test-name',
];

const UAParser = new uaParser();

export const checkFbBots = (): boolean => {
  const windowWidth = window.screen.width;
  const windowHeight = window.screen.height;

  return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};

export const deleteConfig = (): void => {
  KEYS_TO_REMOVE.forEach((key) => localStorage.removeItem(key));

  localStorage.setItem('isResetStore', 'true');
};

export const isEmpty = (value: null | undefined | object | string): boolean => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const scrollToTop = (): void => {
  window.scrollTo(0, 0);
};

export const replaceUnderscore = (string: string): string => string.replace(/_/g, '-');

export const getOnlyPositiveNumber = (digit: number): number => (digit <= 0 ? 1 : digit);

export const enableOnlyDigitsInput = (event: KeyboardEvent): void => {
  if (event.key === 'Tab' || event.key === 'Backspace') {
    return;
  }
  if (event.key < '0' || event.key > '9') {
    event.preventDefault();
  }
};

export const startCalculateProgress = ({
                                         setProgress,
                                         onComplete,
                                       }: {
  setProgress: (progress: number) => void;
  onComplete?: () => void;
}): void => {
  const path = window.location.href;

  let currentProgress = 0;
  let speed = 50;

  setTimeout(frame, speed);

  async function frame() {
    if (currentProgress === 100) {
      currentProgress = 0;
      onComplete && onComplete();
    } else if (path === window.location.href) {
      if (config.ENV === 'prod') {
        if (currentProgress >= 38 && currentProgress < 45) {
          speed = 250;
        } else if (currentProgress >= 45 && currentProgress < 63) {
          speed = 50;
        } else if (currentProgress >= 63 && currentProgress < 75) {
          speed = 60;
        } else if (currentProgress >= 75 && currentProgress < 82) {
          speed = 250;
        } else if (currentProgress >= 82 && currentProgress < 95) {
          speed = 70;
        } else if (currentProgress >= 96) {
          speed = 500;
        }
      } else {
        speed = 5;
      }

      setProgress(Math.round(++currentProgress));
      setTimeout(frame, speed);
    }
  }
};

export const joinClasses = (...arg: string[]): string => {
  return [...arg].join(' ');
};

export const updateGlobalLoaderVisibility = (isVisible: boolean): void => {
  const globalLoader = document.getElementById('app-loader');

  if (globalLoader) {
    globalLoader.style.display = isVisible ? 'block' : 'none';
  }
};

export const validateCountry = (country: unknown): boolean => {
  const countryCodeList = COUNTRIES_LIST.map((el) => el.code);

  return typeof country === 'string' && countryCodeList.includes(country);
};

export const dateNormalizer = (days: number) => {
  if (days === DAYS_PER_YEAR) {
    return '1_year';
  } else if (days === DAYS_PER_MONTH) {
    return '1_month';
  } else if (days === DAYS_PER_TWO_MONTH) {
    return '2_months';
  } else if (days === DAYS_PER_THREE_MONTH) {
    return '3_months';
  } else if (days === DAYS_PER_FOUR_MONTH) {
    return '4_months';
  } else if (days === DAYS_PER_FIVE_MONTH) {
    return '5_months';
  } else if (days === DAYS_PER_SIX_MONTH) {
    return '6_months';
  } else if (days === DAYS_PER_SEVEN_MONTH) {
    return '7_months';
  } else if (days === DAYS_PER_EIGHT_MONTH) {
    return '8_months';
  } else if (days === DAYS_PER_WEEK) {
    return '1_week';
  }

  return '0_null'; // fix broken build
};

export const parsePaymentType = (period: number): string => dateNormalizer(period).replace('_', ' ');

export const periodNormalizer = (period: number, t: any): string => {
  if (period === DAYS_PER_YEAR) {
    return `1 ${t('common.year')}`;
  } else if (period === DAYS_PER_WEEK) {
    return `1 ${t('common.week')}`;
  } else if (period === DAYS_PER_MONTH) {
    return `1 ${t('common.month')}`;
  } else if (period === DAYS_PER_TWO_MONTH) {
    return `2 ${t('common.months')}`;
  } else if (period === DAYS_PER_THREE_MONTH) {
    return `3 ${t('common.months')}`;
  } else if (period === DAYS_PER_FOUR_MONTH) {
    return `4 ${t('common.months')}`;
  } else if (period === DAYS_PER_FIVE_MONTH) {
    return `5 ${t('common.months')}`;
  } else if (period === DAYS_PER_SIX_MONTH) {
    return `6 ${t('common.months')}`;
  } else if (period === DAYS_PER_SEVEN_MONTH) {
    return `7 ${t('common.months')}`;
  } else if (period === DAYS_PER_EIGHT_MONTH) {
    return `8 ${t('common.months')}`;
  }

  return '0_null'; // fix broken build
};

export const getToFixedNumber = (number: string | number, numbersAfterComma = 2): string => {
  const numberValue = Number(number);

  return numberValue.toFixed(numbersAfterComma);
};

export const getPriceFromCents = (price: string | number, numbersAfterComma = 2): string => {
  const priceValue = Number(price);

  return getToFixedNumber(priceValue / 100, numbersAfterComma);
};

export const trialNormalizer = (trial: number, t: any): string => {
  if (trial === DAYS_PER_WEEK) {
    return `1-${t('common.week')} ${t('common.trial')}`;
  } else if (trial === DAYS_PER_MONTH) {
    return `4-${t('common.week')} ${t('common.plan')}`;
  } else if (trial === DAYS_PER_THREE_MONTH) {
    return `12-${t('common.week')} ${t('common.plan')}`;
  } else if (trial === DAYS_PER_SIX_MONTH) {
    return `6-${t('common.month')} ${t('common.plan')}`;
  }

  return '0_null'; // fix broken build
};

export const trialForTotalNormalizer = (trial: number, t: any): string => {
  if (trial === DAYS_PER_WEEK) {
    return `7 ${t('common.days')}`;
  } else if (trial === DAYS_PER_MONTH) {
    return `1 ${t('common.month')}`;
  } else if (trial === DAYS_PER_THREE_MONTH) {
    return `3 ${t('common.months')}`;
  } else if (trial === DAYS_PER_SIX_MONTH) {
    return `6 ${t('common.months')}`;
  }

  return '0_null'; // fix broken build
};

export const replaceToUnderscore = (string: string): string => string.replace(/-/g, '_');
export const replaceToDash = (string: string): string => string.replace(/_/g, '-');

export const getDeviceOS = (): string => {
  const userAgent = window.navigator.userAgent;
  const getOSData = UAParser.setUA(userAgent).getOS();

  return getOSData?.name?.toUpperCase() || 'DEFAULT_DEVICE';
};

export const isString = (value: any): boolean => typeof value.valueOf() === 'string';

export const isObject = (value: any) => {
  const type = typeof value;

  return value !== null && (type === 'object' || type === 'function');
};

export const getLanguage = (): string => localStorage.getItem('language') || DEFAULT_LANGUAGE;

export const getRelease = (): string => {
  return JSON.parse(config.RELEASE);
};

export const cleanObject = <T>(object: Record<string, unknown>): Record<string, unknown> | T => {
  for (const propName in object) {
    if (object[propName] === null || object[propName] === undefined) {
      delete object[propName];
    }
  }

  return object;
};

export const deleteSlash = (word: string): string => word?.replace(/\//g, '');

export const fromPennyToInt = (penny: number): number => penny / 100;

export const generateQueryParams = (): string => {
  // const paramsLocalSt = localStorage.getItem('urlParams');
  const urlParamsFromURL = getUrlParamsFromUrl();
  // @ts-ignore
  const defaultUrlParams = AnalyticUtils.getDefaultUrlParams();

  const params = urlParamsFromURL ? urlParamsFromURL : defaultUrlParams;
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => key === 'intro' ? null : searchParams.append(key, params[key]));

  return searchParams.toString();
};

export const generateQueryParamsForRefresh = () => {
  const urlParamsFromURL = getUrlParamsFromUrl();
  // @ts-ignore
  const defaultUrlParams = AnalyticUtils.getDefaultUrlParams();

  const params = urlParamsFromURL ? urlParamsFromURL : defaultUrlParams;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { token, ...restParams } = params;

  const data = {
    ...restParams,
    ...params['test-name'] && { ['test-name']: `${params['test-name']}_restart` },
  };
  const searchParams = new URLSearchParams(data);

  return searchParams.toString();
};

export const isRemarketingFlow = () => {
  const urlParams = getUrlParams();

  const token = urlParams?.token;

  return Boolean(token);
};

const fromEntriesPolyfill = (iterable: any) => {
  return [...iterable].reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});
};


export const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)?.entries();

  const params = fromEntriesPolyfill(urlSearchParams);

  return isEmpty(params) ? null : params;
};

export const getRandomIntFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const fromFtInToCm = (feet = 0, inches = 0): number => {
  const cmTotal = feet * 30.48 + inches * 2.54;

  return Math.round(cmTotal);
};

export const fromPoundsToKg = (pounds: number): number => {
  return Math.round(pounds * 0.453592);
};

export const calculateBMIInKg = (weight: number, height: number): number => {
  const heightInMeters = height / 100;

  return +(weight / Math.pow(heightInMeters, 2)).toFixed(1);
};

export const getDynamicAge = (birthDate: number): string => {
  const currentYear = new Date().getFullYear();
  const userAge = currentYear - birthDate;


  if (userAge < 31) {
    return '20s';
  } else if (userAge >= 31 && userAge <= 40) {
    return '30s';
  } else if (userAge >= 41 && userAge <= 50) {
    return '40s';
  } else {
    return '50s';
  }
};

export const getEggsCountPercent = (birthDate: number): string => {
  if (!birthDate) return '7%';

  const currentYear = new Date().getFullYear();
  const userAge = currentYear - birthDate;

  if (userAge < 30) {
    return '29%';
  } else if (userAge >= 30 && userAge < 35) {
    return '12%';
  } else if (userAge >= 35 && userAge < 40) {
    return '7%';
  } else {
    return '4%';
  }
};


export const getMaxElemFromArr = (arr: Array<{ [fieldName: string]: number }>, fieldName: string) => {
  return arr.reduce((prev, cur) => (prev[fieldName] > cur[fieldName] ? prev : cur));
};

export const getMinElemFromArr = (arr: Record<string, number>[], fieldName: string) => {
  return arr.reduce((prev, cur) => (prev[fieldName] < cur[fieldName] ? prev : cur));
};

export const getDiscount = (oldPrice: number, newPrice: number): string => {
  return (((oldPrice - newPrice) / oldPrice) * 100).toFixed();
};

export const getParamFromUrl = (paramName: string) => {
  const params = new URLSearchParams(window.location.search);

  return params.get(paramName);
};
