import { EMAIL_BRANCH, LV_BRANCH, MOTHERHOOD_BRANCH, PREGNANCY_BRANCH } from 'constants/branching';
import { GET_PREGNANT_GOAL, TRACK_CYCLE_GOAL, TRACK_PREGNANCY_GOAL } from 'constants/userGoals';

export const getUserGoalByBranchName = (branchName: string | null): string => {
  if (branchName === MOTHERHOOD_BRANCH) return GET_PREGNANT_GOAL;
  if (branchName === LV_BRANCH) return TRACK_CYCLE_GOAL;
  if (branchName === PREGNANCY_BRANCH) return TRACK_PREGNANCY_GOAL;
  // TODO: need to fix userGoal on email branch
  if (branchName === EMAIL_BRANCH) return '0_null';

  return localStorage.getItem('userGoal') || GET_PREGNANT_GOAL;
};
